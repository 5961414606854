import Layout from 'components/Layout'
import React from 'react'

export default function WebinarSignupPage() {
	return (
		<Layout>
			<h1>Sign up to view previous webinars and subscribe to email campaigns</h1>
		</Layout>
	)
}
